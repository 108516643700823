import React from 'react'
import SEO from '../components/seo'
import Card from '../components/card'
import Button from '../components/button'
import { Container, Row, Col } from 'react-grid-system'

const NavLink = props => {
  if (!props.test) return <Button to={props.url}>{props.text}</Button>
  else return null
}

const Posts = (props) => {
  const { group, index, first, last, pageCount, additionalContext } = props.pageContext
  const previousUrl = index - 1 === 1 ? additionalContext.uri : `${additionalContext.uri}${(index - 1).toString()}`
  const nextUrl = `${additionalContext.uri}${(index + 1).toString()}`

  return (
    <>
      <SEO data={additionalContext.seo} />
      <div className="posts">
        <Container>
          <div>Page {index} sur {pageCount}</div>
          <Row>
            {group && group.map((post, i) => {
              post.image = post.featuredImage && post.featuredImage.node
              
              return (
                <Col sm={4} key={i}>
                  <Card {...post}/>
                </Col>
              )
            })}
          </Row>
          <div className="previousLink">
            <NavLink test={first} url={previousUrl} text="Page précédente" />
          </div>
          <div className="nextLink">
            <NavLink test={last} url={nextUrl} text="Page suivante" />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Posts