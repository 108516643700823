import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'

const CardTitle = styled.h3`
  font-family: ${props => props.theme.fonts.headings};
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.orange};
`

const CardWrapper = styled(Link)`
  width: 100%;
  display: inline-block;
  margin: 25px 0;
`

const CardImage = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;

  .gatsby-image-wrapper { height: 100%; }
`

const CardContent = styled.div`
  font-family: ${props => props.theme.fonts.content};
  font-size: 15px;
  line-height: 24px;
  color: #777777;
`
const getExcerpt = (content, nbchars) => {
  return (content.length > nbchars) ? `${content.substring(0, nbchars)}...` : content
}

const Card = (props) => {
  return(
    <CardWrapper to={props.uri}>
      {props.image && <CardImage><Img fluid={ props.image.sourceUrlSharp.childImageSharp.previewFluid } alt={ props.image.altText }/></CardImage>}
      <CardTitle>{props.title}</CardTitle>
      <CardContent>{getExcerpt(props.content.replace(/(<([^>]+)>)/gi, ""), 150)}</CardContent>
    </CardWrapper>
  )
}

export default Card